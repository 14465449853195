import { setCurrentGameBuild, setCurrentGameName, setCurrentGamePureLevel } from 'cfx/base/gameRuntime';
import { GameName } from 'cfx/base/game';
import { Deferred, timeout } from 'cfx/utils/async';
import { IDisposable } from 'cfx/utils/disposable';
import { MultiEventEmitter } from "cfx/utils/eventEmitter";
import { AwaitableValue } from 'cfx/utils/observable';
import { RichEvent } from 'cfx/utils/types';
import { IQueriedServerData } from './services/servers/source/types';

const nuiWindow: (typeof window) & {
  nuiTargetGame: string;
  nuiTargetGameBuild: number;
  nuiTargetGamePureLevel: number;
  nuiSetAudioCategory(category: string): void;
  nuiSystemLanguages: string[];

} = window as any;

const gameTheme = window.location.hostname.includes('servers.redm.net') ? GameName.RedM : GameName.FiveM

// Set this as early as possible
setCurrentGameName(gameTheme);
if (typeof nuiWindow.nuiTargetGameBuild === 'number') {
  setCurrentGameBuild(nuiWindow.nuiTargetGameBuild.toString());
}
if (typeof nuiWindow.nuiTargetGamePureLevel === 'number') {
  setCurrentGamePureLevel(nuiWindow.nuiTargetGamePureLevel.toString());
}

const events = new MultiEventEmitter<TPayload>();
const fileSelectRequests: Record<string, (filePath: string) => void> = {};
const serverQueryRequests: Record<string, Deferred<any>> = {};

export namespace serversList {
  export function onRich<Name extends string, Payload extends TPayload>(
    eventDescriptor: RichEvent.Descriptor<Name, Payload>,
    cb: (data: Payload) => void,
  ): IDisposable {
    return events.addListener(eventDescriptor, cb);
  }
  export function on<T extends TPayload>(eventName: string, cb: (data: T) => void): IDisposable {
    return events.addListener(eventName, cb);
  }

  let showGameWindowRequested = false;
  export function showGameWindow() {
    if (showGameWindowRequested) {
      return;
    }

    showGameWindowRequested = true;
  }


  export const systemLanguages = [...new Set(nuiWindow.nuiSystemLanguages || ['en-us'])];

  export const computerName = new AwaitableValue('');

  export async function selectFile(key: string): Promise<string> {
    return new Promise<string>((resolve) => {
      fileSelectRequests[key] = resolve;
    });
  }

  export function queryServer(fullAddress: string): Promise<IQueriedServerData> {
    if (serverQueryRequests[fullAddress]) {
      return serverQueryRequests[fullAddress].promise;
    }

    const deferred = new Deferred<IQueriedServerData>();

    serverQueryRequests[fullAddress] = deferred;

    timeout(7500).then(() => {
      if (!serverQueryRequests[fullAddress]) {
        return;
      }

      serverQueryRequests[fullAddress].reject(new Error('Server query timed out after 7.5s'));
      delete serverQueryRequests[fullAddress];
    });

    return deferred.promise;
  }

  // Subscribe to wrapped events
  on('setComputerName', (data: { data: string }) => computerName.value = data.data);
  on('fileDialogResult', ({ dialogKey, result }: { dialogKey: string, result: string }) => fileSelectRequests[dialogKey]?.(result));

  on('serverQueried', (data: any) => {
    const deferred = serverQueryRequests[data.queryCorrelation];
    if (!deferred) {
      return;
    }

    delete serverQueryRequests[data.queryCorrelation];

    deferred.resolve(data);
  });

  on('queryFailed', (data: any) => {
    const deferred = serverQueryRequests[data.arg];
    if (!deferred) {
      return;
    }

    delete serverQueryRequests[data.arg];

    deferred.reject(new Error('Query failed'));
  });
}



window.addEventListener('message', (event: MessageEvent) => {
  if (__CFXUI_DEV__) {
    console.log('[WNDMSG]', event.data);
  }

  const { data } = event;

  if (typeof data !== 'object') {
    return;
  }

  if (data === null) {
    return;
  }

  if (!data.type) {
    return;
  }

  events.emit(data.type, data);
});

type TPayload = Record<string, any>;
