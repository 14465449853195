import { observer } from 'mobx-react-lite';
import ReactDOM from 'react-dom';
import { SearchBarState } from '../SearchBar/SearchBarState';

export const InsideNavBar = observer(function InsideNavBar({ children }: ChildrenProps) {
  if (!SearchBarState.ready) {
    return null;
  }

  if (!SearchBarState.outletRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    children,
    SearchBarState.outletRef.current,
  );
});
