import "./bootstrap";
import { startBrowserApp } from "cfx/base/createApp";
import { ServersListApp } from "./components/ServersListApp/ServersListApp";
import { ServersListType } from "cfx/common/services/servers/lists/types";
import { HomePage } from "./pages/HomePage/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { registerLogService } from "cfx/common/services/log/logService";
import { ConsoleLogProvider } from "cfx/common/services/log/providers/consoleLogProvider";
import { registerServersListServersService } from "./services/servers/servers.serversList";
import { IServersService } from "cfx/common/services/servers/servers.service";
import { IIntlService } from "cfx/common/services/intl/intl.service";
import { registerServersListServersStorageService } from "./services/servers/serversStorage.serversList";
import {
  IUiMessageService,
  registerUiMessageService,
} from "./services/uiMessage/uiMessage.service";
import { IUiService } from "cfx/common/services/ui/ui.serviceList";
import { registerServersListUiService } from "./services/ui/ui.serversList";
import { registerServersListIntlService } from "./services/intl/intl.serversList";
import { registerConvarService } from "./services/convars/convars.service";
import { ServersListServerDetailsPage } from "./pages/ServerDetailsPage/ServerDetailsPage";
import { registerActivityService } from "cfx/common/services/activity/activity.service";
import { registerServersListServersConnectService } from "./services/servers/serversConnect.serversList";

startBrowserApp({
  defineServices(container) {
    registerLogService(container, [ConsoleLogProvider]);
    registerUiMessageService(container);
    registerServersListIntlService(container);
    registerConvarService(container);
    registerServersListServersConnectService(container);
    registerActivityService(container);
    registerServersListServersStorageService(container);
    registerServersListUiService(container);
    registerServersListServersService(container, {
      listTypes: [ServersListType.All],
    });
  },

  beforeRender(container) {
    // Pre-resolve critical services
    [IUiService, IUiMessageService, IIntlService, IServersService].forEach(
      (serviceId) => container.get(serviceId)
    );
  },

  render: () => (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<ServersListApp />}>
        <Route index element={<HomePage listType={ServersListType.All} />} />
        <Route path="detail/*" element={<ServersListServerDetailsPage />} />
      </Route>
      <Route path="/servers" element={<ServersListApp />}>
        <Route index element={<HomePage listType={ServersListType.All} />} />
        <Route path="detail/*" element={<ServersListServerDetailsPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
  ),

  afterRender() {
  },
});
