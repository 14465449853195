import React from "react";
import { ServerFilters } from "cfx/common/parts/Server/ServerFilters/ServerFilters";
import { ServerListConfigController } from "cfx/common/services/servers/lists/ServerListConfigController";
import { observer } from "mobx-react-lite";
import {
  userServerSearchController,
} from "./ServerSearchController";

export interface ServerSearchProps {
  config: ServerListConfigController;
}
export const ServerSearch = observer(
  function ServerSearch(
    props: ServerSearchProps
  ) {
    const { config } = props;

    const inputRef = React.useRef<HTMLElement>(null);
    const controller = userServerSearchController();
    {
      controller.config = config;
    }

    React.useEffect(() => {
      controller.setSearchTerms(config.searchTextParsed);
    }, [controller, config.searchText]);

    return (
      <ServerFilters
        config={config}
        inputRef={inputRef}
        onInputActive={controller.setInputActive}
        onInputKeyDown={controller.handleInputKeyDown}
      />
    );
  }
);
