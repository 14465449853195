import React from "react";
import { LinkButton } from "cfx/ui/Button/LinkButton";
import { BrandIcon } from "cfx/ui/Icons";
import { Title } from "cfx/ui/Title/Title";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { clsx } from "cfx/utils/clsx";
import { CurrentGameName } from "cfx/base/gameRuntime";
import { $L } from "cfx/common/services/intl/l10n";
import s from "./HomeButton.module.scss";

const titles = [
  "Home", // Unused, we're using localized version for this one
  "Home Home",
  "Home Home Home",
  "Can you stop?",
  "Please",
  "This won't help",
  "Seriously",
  "Home",
  "This didn't quite work, right?",
  "To the beginning",
  "Home",
  "Wait, no",
  "Oh yea, press me more",
  "Now for real, to the beginning",
];

export const HomeButton = observer(function HomeButton() {
  const [index, setIndex] = React.useState(0);

  const resetRef = React.useRef(undefined);

  const handleClick = () => {
    let newIndex = index + 1;
    if (newIndex > titles.length - 1) {
      newIndex = 0;
    }

    setIndex(newIndex);

    if (resetRef.current) {
      clearTimeout(resetRef.current);
    }

    resetRef.current = setTimeout(() => {
      setIndex(0);
    }, 2000) as any;
  };

  const location = useLocation();

  React.useEffect(() => {
    if (resetRef.current) {
      clearTimeout(resetRef.current);
    }

    setIndex(0);
  }, [location.pathname]);

  const title = index === 0 ? $L("#BottomNav_Home") : titles[index];

  return (
    <div className={clsx(s.homepagebutton)}>
      <Title fixedOn="bottom-left" title={title}>
        <>
          <LinkButton
            to={window.location.hostname.includes("servers.redm.net")
            ? "https://redm.net/"
            : "https://fivem.net/"}
            size="large"
            theme="transparent"
            icon={BrandIcon[CurrentGameName] || BrandIcon.cfxre}
            className={clsx(s.root, s.specific)}
            onClick={handleClick}
            target="_self"
            text={window.location.hostname.includes("servers.redm.net")
            ? "RedM"
            : "FiveM"}
          />
        </>
      </Title>
    </div>
  );
});
