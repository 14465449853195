import { Outlet } from "react-router-dom";
import s from "./ServersListApp.module.scss";
import { observer } from "mobx-react-lite";
import { NavBar } from "../../parts/NavBar/NavBar";
import { ThemeManager } from "../../parts/ThemeManager/ThemeManager";
import { SearchBar } from "../../parts/SearchBar/SearchBar";

function ServersListUi() {
  return (
    <>
      <div className={s.root}>
      <NavBar />
        <SearchBar />
        <div className={s.outlet}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
export const ServersListApp = observer(function ServersListApp() {
  return (
    <>
      <ThemeManager />
      <ServersListUi />
    </>
  );
});
