import { ServersPage } from "cfx/common/pages/ServersPage/ServersPage";
import { useService } from "cfx/base/servicesContainer";
import { ServersListType } from "cfx/common/services/servers/lists/types";
import { IServersService } from "cfx/common/services/servers/servers.service";
import { Flex } from "cfx/ui/Layout/Flex/Flex";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { currentGameNameIs } from "cfx/base/gameRuntime";
import { GameName } from "cfx/base/game";
import { clsx } from "cfx/utils/clsx";
import s from "./HomePage.module.scss";
import { InsideNavBar } from "../../parts/NavBar/InsideNavBar";
import { ServerSearch } from "./ServerSearch/ServerSearch";

export interface ServersPageProps {
  listType: ServersListType;
}
export const HomePage = observer(function ({ listType }: ServersPageProps) {
  const ServersService = useService(IServersService);

  const serversList = ServersService.getList(listType);
  if (!serversList) {
    return <Navigate to="/servers" />;
  }

  const config = serversList.getConfig?.();
  const showPinned =
    currentGameNameIs(GameName.FiveM) && listType === ServersListType.All;

  const navbarClassName = clsx(s.navbar, {
    [s.shrink]: showPinned,
  });

  return (
    <>
      <InsideNavBar>
        <Flex gap="large" className={navbarClassName}>

          {!!config && (
            <ServerSearch config={config} />
          )}
        </Flex>
      </InsideNavBar>
      <ServersPage
        list={serversList}
        listType={listType}
        showPinned={showPinned}
      />
    </>
  );
});
