import React from "react";
import { observer } from "mobx-react-lite";
import { SearchBarState } from "./SearchBarState";
import { Flex } from "cfx/ui/Layout/Flex/Flex";
import { Box } from "cfx/ui/Layout/Box/Box";

export const SearchBar = observer(function NavBar() {
  React.useEffect(() => {
    SearchBarState.setReady();

    return SearchBarState.setNotReady;
  }, []);

  return (
    <Flex repell centered gap="large">
      <Box grow ref={SearchBarState.outletRef} />
    </Flex>
  );
});
